/**
 * Created by zal on 2020/11/2
 * 公共头部
 */
import React, { Component } from 'react';
import './commonHeader.scss';
import './mobileHeader.scss';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { isMobile } from '../../utils/util';
import mobileLogoPNG from '../../assets/images/common/mobileLogo.png';
import mobileMenuIconPNG from '../../assets/images/common/mobileMenuIcon.png';
import { Children } from 'react';

const { SubMenu } = Menu;

export default class CommonHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subMenuData1: [
                {
                    name: '区块链',
                    children: [
                        { name: 'AndChain区块链底层平台', url: '/andChain', key: 'andChain' },
                        { name: 'AndLogistics物流管理平台', url: '/andLogistics', key: 'andLogistics' },
                        { name: 'AndFinance供应链金融平台', url: '/andFinance', key: 'andFinance' },
                        { name: 'AndTracking溯源平台', url: '/andTracking', key: 'andTracking' }
                    ]
                },
                {
                    name: '大数据与人工智能',
                    children: [
                        { name: 'Anstar企业行为安全感知平台', url: '/anstar', key: 'anstar' },
                        { name: 'Compass智能图分析平台', url: '/compass', key: 'compass' }
                    ]
                }
            ],
            subMenuData2: [
                { name: '区块链存证', url: '/blockchainCertificate', key: 'blockchainCertificate' },
                { name: '区块链数字版权', url: '/blockchainCopyright', key: 'blockchainCopyright' },
                { name: '区块链电子证照', url: '/blockchainLicense', key: 'blockchainLicense' },
                { name: '区块链网络赋强公证', url: '/blockchainNetwork', key: 'blockchainNetwork' }
            ],
            menuData: [
                { name: '新闻中心', url: '/news', key: 'news' },
                {
                    name: '关于安链',
                    url: '/about', key: 'about',
                    children: [
                        { name: '公司介绍', url: '/about/1', key: 'about1' },
                        { name: '企业文化', url: '/about/2', key: 'about2' },
                        { name: '团队风采', url: '/about/3', key: 'about3' },
                        { name: '合作伙伴', url: '/about/4', key: 'about4' },
                        { name: '荣誉资质', url: '/about/5', key: 'about5' },
                        { name: '联系方式', url: '/about/6', key: 'about6' }
                    ]
                },
                { name: '加入我们', url: '/joinus', key: 'joinus' }
            ],
            subMenuKey: '',
            showMenu: false
        };
    }

    componentDidMount() {
        let pathname = window.location.pathname.replace('/', '');
        if (pathname.indexOf('about') !== -1) {
            this.setState({
                current: 'about1'
            })
        } else {
            this.setState({
                current: pathname === '' ? '0' : pathname
            })
        }
    }

    setSubMenuKey = (newSubMenuKey) => {
        let { subMenuKey } = this.state;
        this.setState({ subMenuKey: subMenuKey === newSubMenuKey ? '' : newSubMenuKey });
    };

    showMenu = () => {
        let { showMenu } = this.state;
        this.setState({ showMenu: !showMenu }, () => {
            let { showMenu } = this.state;
            if (showMenu) {
                document.body.style.overflow = 'hidden';
                document.documentElement.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
                document.documentElement.style.overflow = 'auto';
            }
        });
    };

    hideMenu = () => {
        this.setState({ showMenu: false }, () => {
            document.body.style.overflow = 'auto';
            document.documentElement.style.overflow = 'auto';
        });
    };

    toCustom() {
        window.open('https://cusplus.com/#/index');
    }

    renderMobileHeader = () => {
        let { subMenuData1, subMenuData2, menuData, subMenuKey, showMenu } = this.state;
        return <div className={'mobileHeader'}>
            <Link to={'/'} onClick={this.hideMenu}>
                <img className={'mobileHeaderLogo'} src={mobileLogoPNG} alt="" />
            </Link>
            <img className={'mobileMenuIcon'} onClick={this.showMenu} src={mobileMenuIconPNG} alt="" />
            {
                showMenu ?
                    <div className={'mobileHeaderMenuRoot'}>
                        <div className={'mobileHeaderMenu'}>
                            <div className={'mobileHeaderSubMenuRoot'}>
                                <div onClick={this.setSubMenuKey.bind(this, 0)} className={'mobileHeaderSubMenuTitle'}>
                                    <span>核心产品</span>
                                    {subMenuKey === 0 ? <MinusOutlined /> : <PlusOutlined />}
                                </div>
                                <div
                                    className={`mobileHeaderSubMenu ${subMenuKey === 0 ? 'mobileHeaderSubMenuActive' : ''}`}>
                                    {
                                        subMenuData1.map(item => {
                                            return <div key={item.name}>
                                                <div
                                                    className={'mobileHeaderSubMenuItem menuSubTitle'}>{item.name}</div>
                                                {
                                                    item.children.map(childrenItem => {
                                                        return <Link onClick={this.hideMenu} to={childrenItem.url} key={childrenItem.name}>
                                                            <div
                                                                className={'mobileHeaderSubMenuItem'}>{childrenItem.name}</div>
                                                        </Link>;
                                                    })
                                                }
                                            </div>;
                                        })
                                    }
                                </div>
                            </div>
                            <div className={'mobileHeaderSubMenuRoot'}>
                                <div onClick={this.setSubMenuKey.bind(this, 1)} className={'mobileHeaderSubMenuTitle'}>
                                    <span>解决方案</span>
                                    {subMenuKey === 1 ? <MinusOutlined /> : <PlusOutlined />}
                                </div>
                                <div
                                    className={`mobileHeaderSubMenu ${subMenuKey === 1 ? 'mobileHeaderSubMenuActive' : ''}`}>
                                    {
                                        subMenuData2.map(childrenItem => {
                                            return <Link to={childrenItem.url} key={childrenItem.name} onClick={this.hideMenu}>
                                                <div className={'mobileHeaderSubMenuItem'}>{childrenItem.name}</div>
                                            </Link>;
                                        })
                                    }
                                </div>
                            </div>
                            {
                                menuData.map(item => {
                                    return <div className={'mobileHeaderSubMenuRoot'} key={item.name}>
                                        <Link to={item.url} onClick={this.hideMenu}>
                                            <div className={'mobileHeaderSubMenuTitle'}>
                                                <span>{item.name}</span>
                                            </div>
                                        </Link>
                                    </div>;
                                })
                            }
                            {/* <div className={'mobileHeaderSubMenuRoot'}>
                                <div className={'mobileHeaderSubMenuTitle'}>
                                <a href='https://cusplus.com' className={'headerMenuName'} target='_blank' rel="noopener noreferrer">客加SCRM</a>                                </div>
                            </div> */}
                        </div>
                        <div onClick={this.hideMenu} className={'mobileMenuMask'} />
                    </div>
                    : null
            }
        </div>;
    };

    handleClick = e => {
        if (e !== 'about') {
            this.setState({ current: e.key });
        } else {
            this.setState({ current: 'about1' });
        }
    };

    render() {
        let { subMenuData1, subMenuData2, menuData } = this.state;
        let { current } = this.state;
        return (
            isMobile() ?
                this.renderMobileHeader()
                :
                <div className={'commonHeader'}>
                    <div className="headerContent">
                        <Link to="/">
                            <div onClick={() => this.setState({
                                current: '0'
                            })}
                                className="commonHeaderLogo" />
                        </Link>
                        <Menu
                            // theme="dark"
                            mode="horizontal"
                            selectedKeys={[current]}
                            onClick={this.handleClick}
                            style={{ lineHeight: '.6rem', background: 'transparent', marginLeft: '3rem' }}>
                            <Menu.Item className={'homeMenu'} key="0">
                                <Link to="/" className={'headerMenuName'}>首页</Link></Menu.Item>
                            <SubMenu key="1" title={<span className={'headerMenuName'}>核心产品</span>}>
                                {
                                    subMenuData1.map(item => {
                                        return <Menu.ItemGroup title={item.name} key={item.name}>
                                            {
                                                item.children.map(childrenItem => {
                                                    return <Menu.Item key={childrenItem.key}>
                                                        <Link to={childrenItem.url}
                                                            className={'headerMenuName'}>{childrenItem.name}</Link>
                                                    </Menu.Item>;
                                                })
                                            }
                                        </Menu.ItemGroup>;
                                    })
                                }
                            </SubMenu>
                            <SubMenu key="2" title={<span className={'headerMenuName'}>解决方案</span>}>
                                {
                                    subMenuData2.map(item => {
                                        return <Menu.Item key={item.key}>
                                            <Link to={item.url} className={'headerMenuName'}>{item.name}</Link>
                                        </Menu.Item>;
                                    })
                                }
                            </SubMenu>
                            {
                                menuData.map(item => {
                                    return !item.children ? <Menu.Item key={item.key}>
                                        <Link to={item.url} className={'headerMenuName'}>{item.name}</Link>
                                    </Menu.Item>
                                        :
                                        <SubMenu key={item.key} title={<span className={'headerMenuName'}
                                        >
                                            <Link to={item.url} className={'headerMenuName'}
                                                onClick={() => this.handleClick('about')}
                                            >{item.name}</Link>
                                        </span>}>
                                            {
                                                item.children.map(childrenItem => {
                                                    return <Menu.Item key={childrenItem.key}>
                                                        <Link to={childrenItem.url} className={'headerMenuName'}>{childrenItem.name}</Link>
                                                    </Menu.Item>;
                                                })
                                            }
                                        </SubMenu>
                                })
                            }
                            {/* <Menu.Item key={'kejia'}>
                                <a href='https://cusplus.com' className={'headerMenuName'} target='_blank' rel="noopener noreferrer">客加SCRM</a>
                            </Menu.Item> */}
                        </Menu>
                    </div>
                    <div className="scrmBtn" onClick={this.toCustom}>
                        客加SCRM
                    </div>
                </div>
        );
    }
}

